import { Component, EntityAccessor, EntityListAccessor, EntitySubTree, Field, FieldView, HasMany, HasOne, If, useCurrentRequest, useEntity } from '@contember/interface'
import { Binding, PersistButtonLarge } from '~/lib/binding'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { LayoutContext } from '../components/layout'
import { RichTextRenderer } from '../components/renderer'
import { CheckboxField, InputField, TextareaField } from '~/lib/form'
import { Textarea } from '~/lib/ui/textarea'
import { QuestionText } from '../components/questionText'
import { QuestionRadio } from '../components/questionRadio'
import { QuestionCheckbox } from '../components/questionCheckbox'
import { Button } from '~/lib/ui/button'
import "../../invitation.css"

export default () => {
	const { layout, setLayout } = useContext(LayoutContext)!
	const request = useCurrentRequest()

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setLayout('invitation')
	}, [])

	return (
		<>
			<Binding>
				<EntitySubTree entity={`Invitation(id='${request?.parameters.id}')`}>
					<h1 className="text-center font-semibold text-2xl md:text-4xl mb-10 md:mb-10">Svatba Veroniky a Tomáše</h1>
					<div className="my-10 mb-14 md:my-14 text-lg">
						<FieldView
							field="introText"
							render={({ value }) => <RichTextRenderer value={value} />}
						/>
					</div>
					<Questionnaire />
				</EntitySubTree>
			</Binding>
		</>
	)
}

const Questionnaire = Component(
	() => {
		const [responseMode, setResponseMode] = useState(false)
		const entity = useEntity()
		const response = entity.getEntity('response')
		const answers = response.getEntityList('answers')
		const rsvpForm = entity.getEntity('rsvpForm')
		const questions = rsvpForm.getEntityList({ field: "questions", orderBy: [{ displayOrder: "asc" }] })
		const invitees = entity.getEntityList({ field: 'invitees', orderBy: [{ displayOrder: 'asc' }] })

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			for (const invitee of invitees) {
				if (invitee.getField('willAttend').valueOnServer === null) {
					invitee.getField('willAttend').updateValue(true)
				}
			}
		}, [])

		if (response.existsOnServer && !responseMode) {
			return <div>
				<h2 className="font-semibold text-xl">Děkujeme za vyplnění dotazníku</h2>
				<div className="my-2 text-center">
					<Button size="lg" onClick={() => setResponseMode(true)}>
						Chci své odpovědi ještě změnit
					</Button>
				</div>
			</div>
		}




		return <>
			<h2 className="font-semibold text-2xl mb-2">Potvrďte účast</h2>
			<p className="mb-5 text-gray-500">Zaškrtnuté pole znamená, že se daná osoba zúčastní.</p>
			<div className="[&_label]:!text-base mb-14">
				<HasMany field="invitees" orderBy="displayOrder">
					<CheckboxField field="willAttend" label={<Field field="name" />} defaultValue={true} />
				</HasMany>
			</div>
			<If condition={() => Array.from(invitees).every(it => it.getField('willAttend').value === false)}>
				<TextareaField field="rejectReason" label="Mrzí nás, že nepřijedete. Chcete nám něco vzkázat?" />
				<div className="my-2 text-center">
					<PersistButtonLarge label="Odeslat" />
				</div>
			</If>
			<If condition={() => Array.from(invitees).some(it => it.getField('willAttend').value === true)}>
				<>
					<h2 className="font-semibold text-2xl mb-5">Zodpovězte nám prosím několik otázek</h2>
					<div className="my-14">
						<InputField
							inputProps={{ type: 'email' }}
							field="response.contactEmail"
							label="Váš e-mail"
							description="Poprosíme o kontaktní e-mail, na který vám pošleme před svatbou ještě všechny potřebné a detailnější informace."
						/>
					</div>
					{Array.from(questions).map((question, index) => {
						const foundAnswer = Array.from(answers).find(it => it.getEntity('question').idOnServer === question.idOnServer)
						return (<div key={index} className="my-14">
							<Question
								key={index}
								foundQuestion={question}
								foundAnswer={foundAnswer}
								answers={answers}
							/>
						</div>)
					})}
					<div className="my-2 text-center">
						<PersistButtonLarge label="Uložit" />
					</div>
				</>
			</If>

		</>


	},
	() => (<>
		<Field field="rejectReason" />
		<HasMany field="invitees" orderBy="displayOrder">
			<Field field="willAttend" />
			<Field field="name" />
		</HasMany>
		<HasOne field="response">
			<Field field="contactEmail" />
			<HasMany field="answers">
				<HasOne field="question" />
				<HasMany field="selectedOptions">
					<Field field="value" />
					<HasOne field="leadsTo" />
				</HasMany>
				<Field field="answerText" />
			</HasMany>
		</HasOne>
		<HasOne field="rsvpForm">
			<HasMany field="questions" orderBy="displayOrder asc">
				<Field field="questionText" />
				<Field field="questionType" />
				<HasMany field="options" orderBy="displayOrder asc">
					<Field field="value" />
					<HasOne field="leadsTo" />
				</HasMany>
			</HasMany>
		</HasOne>
	</>),
	'Questionnaire',
)


const Question: FunctionComponent<{ foundQuestion: EntityAccessor, foundAnswer: EntityAccessor | undefined, answers: EntityListAccessor }> = ({ foundQuestion, foundAnswer, answers }) => {
	switch (foundQuestion.getField<string>('questionType').value) {
		case "radio": {
			const options = foundQuestion.getEntityList({ field: 'options', orderBy: [{ displayOrder: 'asc' }] })
			const selectedOptions = foundAnswer ? Array.from(foundAnswer?.getEntityList('selectedOptions')) : undefined
			return <QuestionRadio
				id={foundQuestion.idOnServer as string ?? ''}
				label={foundQuestion.getField<string>('questionText').value ?? ''}
				options={Array.from(options).map(option => ({
					value: option.getField<string>('value').value ?? '',
					label: option.getField<string>('value').value ?? '',
				}))}
				selectedOption={selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].getField<string>('value').value ?? undefined : undefined}
				onChange={(_, value) => {
					const foundOption = Array.from(options).find(it => it.getField<string>('value').value === value)
					if (foundAnswer) {
						const selectedOptions = foundAnswer?.getEntityList('selectedOptions')
						selectedOptions.disconnectAll()
						if (foundOption) {
							selectedOptions.connectEntity(foundOption)
						}
					} else {
						answers.createNewEntity(newEntity => {
							newEntity().connectEntityAtField('question', foundQuestion)
							if (foundOption) {
								newEntity().getEntityList('selectedOptions').connectEntity(foundOption)
							}
						})
					}
				}}

			/>
		}
		case "checkbox": {
			const options = foundQuestion.getEntityList({ field: 'options', orderBy: [{ displayOrder: 'asc' }] })
			const selectedOptions = foundAnswer ? Array.from(foundAnswer?.getEntityList('selectedOptions')) : undefined
			return <QuestionCheckbox
				id={foundQuestion.idOnServer as string ?? ''}
				label={foundQuestion.getField<string>('questionText').value ?? ''}
				options={Array.from(options).map(option => ({
					value: option.getField<string>('value').value ?? '',
					label: option.getField<string>('value').value ?? '',
				}))}
				selectedOptions={selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(it => it.getField<string>('value').value) as string[] : undefined}
				onChange={(_, value) => {
					const foundOption = Array.from(options).find(it => it.getField<string>('value').value === value)
					if (foundAnswer) {
						const selectedOptions = foundAnswer?.getEntityList('selectedOptions')
						if (foundOption) {
							selectedOptions.connectEntity(foundOption)
						}
					} else {
						answers.createNewEntity(newEntity => {
							newEntity().connectEntityAtField('question', foundQuestion)
							if (foundOption) {
								newEntity().getEntityList('selectedOptions').connectEntity(foundOption)
							}
						})
					}
				}}

			/>
		}
		default:
			return <QuestionText
				label={foundQuestion.getField<string>('questionText').value ?? ''}
			>
				<Textarea
					className="bg-white"
					value={foundAnswer?.getField<string>('answerText').value ?? ''}
					onChange={event => {
						foundAnswer
							? foundAnswer.getField<string>('answerText').updateValue(event.target.value)
							: answers.createNewEntity(newEntity => {
								newEntity().connectEntityAtField('question', foundQuestion)
								newEntity().getField<string>('answerText').updateValue(event.target.value)
							})
					}}
				/>
			</QuestionText>
	}
}