import { createContext, Dispatch, SetStateAction, useState } from "react"
import { Card } from "~/lib/ui/card"

type Layout = 'default' | 'invitation'

interface LayoutContextProps {
	layout: Layout
	setLayout: Dispatch<SetStateAction<'default' | 'invitation'>>
}

export const LayoutContext = createContext<LayoutContextProps | undefined>(undefined)

export const Layout = ({ children }: { children?: React.ReactNode }) => {
	const [layout, setLayout] = useState<Layout>('default')

	return (<LayoutContext.Provider value={{ layout, setLayout }}>
		{layout === 'invitation'
			? <div className="w-full md:max-w-[750px] mx-auto md:my-10 fontSaans">
				<Card className="px-4 pt-12 pb-7 md:pt-10 md:px-6 rounded-none md:rounded shadow-none md:shadow-sm border-0 md:border bg-[#def1e7]">{children}</Card>
			</div>
			: <div className="grid md:grid-cols-2 min-h-screen">
				<div className="bg-gray-100 px-2 py-4 md:p-4 flex items-center justify-center">
					{children}
				</div>
				<div className="bg-gray-900 text-white p-4 flex items-center justify-center">
					<div className="w-full max-w-md">
						<div className="text-center text-2xl">Klimszová Langer</div>
					</div>
				</div>
			</div>

		}

	</LayoutContext.Provider>)
}

